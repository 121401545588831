.list {
  &.regions {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5em;

    @include min-size(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include min-size(ms) {
      grid-template-columns: repeat(8, 1fr);
    }

    .region {
      cursor: pointer;
      color: $color-mid-grey;
      background: white;
      border: 1px solid $color-super-light;
      padding: 0.5em;
      font-size: .8em;
      font-weight: 600;
      transition: all ease-in-out .2s;
      .title {
        text-align: center;
        padding: 0.5em;
        width: 100%;

        .name {
          font-size: 1.2em;
        }
        .count {
          font-size: 0.9em;
          color: $color-lighter-grey;
        }
      }
      &:hover {
        background: $color-brand;
        color: white;
        border-color: $color-brand;
      }
    }
  }
}
