.destination {
  .destination-image {
    overflow: hidden;
    position: relative;
    height: 14em;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }


  }
  .title {
    color: $color-mid-grey;
    text-align: center;
    padding: 0.5em;
    width: 100%;

    .name {
      font-size: 1.2em;
    }
    .count {
      font-size: 0.9em;
      color: $color-lighter-grey;
    }
  }
}
