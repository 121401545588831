.search-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: rgba(0,0,0,0.75);
}

.search {
  background: white;
  position: relative;
  padding: 1em;
  margin-top: 1em;
  color: $color-grey;
  text-shadow: initial;

  .search-input {
    display: flex;
    justify-items: center;
    align-items: center;

    input {
      border: 0;
      font-size: 1em;
      flex: 1;
      outline: none;
    }
  }

  .suggestion {
    color: $color-grey;
    padding: .5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all ease-in-out .2s;
    font-size: 0.9em;

    &.highlighted, &:hover {
      background: $color-brand;
      color: white;
    }

    p {
      margin: 0 10px;
    }
  }

  .suggestions {
    position: absolute;
    background: white;
    border: 1px solid $color-light-grey;
    z-index: 2;
    padding: 10px;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 1em;

    h1 {
      font-size: 1.2em;
      font-weight: 300;
      margin-bottom: 1em;
    }

    .buttons {
      display: grid;
      grid-gap: 1em;
      grid-template-columns: repeat(1, 1fr);
      @include min-size(sm) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .button {
      color: white;
      display: inline-block;
      background: $color-brand;
      border-radius: 3px;
      border: 1px solid $color-light-grey;
      padding: .5em 1em;
      font-size: .9em;
      font-weight: 600;
      text-align: center;
      transition: all ease-in-out .2s;
      letter-spacing: initial;
      &:hover {
        border-color: $color-brand;
        background: $color-brand;
        color: white;
      }
    }
  }
}

.search-bar {
  position: relative;
  width: 100%;
  padding: .5em 5%;
  background: white;
  border-bottom: 2px solid $color-light-grey;
  display: flex;
  .search-container {
    flex: 1;
    position: relative;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .search {
    // padding: 10px 0;
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    flex: 3;
    background: white;
    .tags {
      padding: 0 .5em;

      .tag {
        padding: 0.5em .8em;
        background: $color-super-light;
        font-weight: 600;
        color: $color-grey;
      }
    }


    .search-input {
      position: relative;
      flex:1;
    }



    input {
      flex: 1;
      background: transparent;
      width: 100%;
      padding: 10px;
      border: none;
      font-size: 1em;
      outline: none;
      font-weight: 600;
    }

    button {
      background: $color-brand;
      border: none;
      color: white;
      padding: 1em 3em;
      font-size: 1em;
      font-weight: 300;
      border-radius: 0;
      &.link {
        background: none;
        color: $color-brand;
        padding: 0;
        font-weight: 600;
      }

      &.grey {
        color: $color-grey;
      }
    }

    .icon {
      font-size: 1.5em;
    }

  }

  .filters {
    border-left: 1px solid $color-light-grey;
    display: flex;
    padding: 0 0.5em;

    .filter {
      position: relative;
      margin: 0 .2em;
    }

    .jumpbox {
      position: absolute;
      top: 4em;
      left: 0;
      background: white;
      border: 1px solid $color-light-grey;
      padding: 1em;
      &.guests {

      }
    }

    .input-group {
      display: flex;
      min-width: 180px;
      align-items: center;
      margin: .5em 0;
      .title {
        flex: 3;

        p {
          margin: 0;
          font-size: .6em;
        }
      }

      .input {
        flex: 2;
        text-align: right;

        .disabled {
          color: $color-mid-grey;
        }

        button {
          background: none;
          color: inherit;
          padding: initial;
          margin-left: .5em;
        }
      }

      &.guests {
        font-size: 1.4em;

        .input {
          color: $color-brand;
        }
      }
    }

    .button {
      color: $color-grey;
      display: inline-block;
      background: white;
      border: 1px solid $color-light-grey;
      padding: .5em 1em;
      font-size: 1em;
      font-weight: 600;
      transition: all ease-in-out .2s;
      &:hover {
        border-color: $color-brand;
        background: $color-brand;
        color: white;
      }
    }
  }


}
