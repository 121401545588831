
$color-bg: white;
$color-black: #111;
$color-main: #565a5c;
$color-brand: #33CCCC;
$color-yellow: #ecb834;

$color-grey: #484848;
$color-super-light: #f9f9f9;

$color-warning: #990000;

$color-mid-grey: lighten($color-grey, 40%);
$color-lighter-grey: lighten($color-grey, 60%);
$color-light-grey: lighten($color-grey, 68%);
$color-light-brand: lighten($color-brand, 45%);
$color-mid-brand: lighten($color-brand, 20%);

.brand {
  color: $color-brand;
}
