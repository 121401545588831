.promo {
  position: relative;
  display: flex;
  background: $color-super-light;
  margin: 0 5%;
  font-size: 1.2em;


  &.large {
    min-height: 75vh;
  }

  &.center {
    .banner-link {
      text-align: center;
      max-width: initial;
      background: rgba(0,0,0,0.2);
    }
  }

  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .image {
    flex: 1;
    &.wide {
      flex: 3;
    }
  }

  .text {
      flex: 2;
      align-self: center;
      padding: 2em 5%;
  }

  button, .button {
    display: inline-block;
      background: white;
      border: 1px solid $color-light-grey;
      padding: 1em 2em;
      font-size: .9em;
      color: $color-grey;
      font-weight: 600;
      letter-spacing: .1em;
      text-shadow: initial;

      &.blue {
        background: $color-brand;
        color: white;
      }

      @include max-size(sm) {
        width: 100%;
        text-align: center;
      }
  }

  h1 {
    font-weight: 300;
    font-size: 2em;
    font-weight: 600;
    margin: 0;
  }

  h2 {
    font-weight: 400;
    margin: 0;
  }

}
