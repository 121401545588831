.filters-bar {
  position: relative;
  width: 100%;
  border-bottom: 1px solid $color-super-light;
  padding: .5em 5%;
  background: #f9f9f9;

  .button {
    color: $color-grey;
    display: inline-block;
    background: white;
    border: 1px solid $color-light-grey;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: 600;
    margin: 5px;
    transition: all ease-in-out .2s;
    &:hover {
      border-color: $color-brand;
      background: $color-brand;
      color: white;
    }
  }

  .search {
    background: white;
    padding: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;

    input {
      flex:1;
      padding: 10px;
      border: none;
      font-size: 1em;
      outline: none;
    }




    .icon {
      font-size: 1.5em;
      margin: .5em;
    }

    .guests {
      display: flex;
      align-items: center;
      padding: 0 1em;
      border-left: 1px solid $color-super-light;
      border-right: 1px solid $color-super-light;
      margin-right: 1em;

      p {
        margin: .1em;
      }

      .icon {
        margin: .2em;
        color: $color-mid-grey;
        &:hover {
          color: $color-brand;
        }
      }

    }
  }

  .suggestion {
    color: $color-grey;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all ease-in-out .2s;
    &.highlighted, &:hover {
      background: $color-brand;
      color: white;
    }

    p {
      margin: 0 10px;
    }
  }

  .suggestions {
    position: absolute;
    background: white;
    border: 1px solid $color-light-grey;
    z-index: 2;
    padding: 10px;
    left: 0;
    right: 0;

    h1 {
      font-size: 1.4em;
      margin-left: 5px;
    }

    > p {
      margin: 0px 5px 20px;
    }


  }

  .filters {
    display: flex;
    padding: 0;

    .filter {
      position: relative;
      margin: 0 .2em;
    }

    .jumpbox {
      position: absolute;
      top: 4em;
      left: 0;
      background: white;
      border: 1px solid $color-light-grey;
      padding: 1em;
      &.guests {

      }
    }

    .input-group {
      display: flex;
      min-width: 180px;
      align-items: center;
      margin: .5em 0;
      .title {
        flex: 3;

        p {
          margin: 0;
          font-size: .6em;
        }
      }

      .input {
        flex: 2;
        text-align: right;

        .disabled {
          color: $color-mid-grey;
        }

        button {
          background: none;
          color: inherit;
          padding: initial;
          margin-left: .5em;
        }
      }

      &.guests {
        font-size: 1.4em;

        .input {
          color: $color-brand;
        }
      }
    }

    .button {
      color: $color-grey;
      display: inline-block;
      background: white;
      border: 1px solid $color-light-grey;
      padding: .5em 1em;
      font-size: 1em;
      font-weight: 600;
      transition: all ease-in-out .2s;
      &:hover {
        border-color: $color-brand;
        background: $color-brand;
        color: white;
      }
    }
  }
}
