.navigation {
  background: white;
  width: 100%;
  max-height: 90px;
  top: 0;
  border-bottom: 2px solid $color-light-grey;
  padding: 1em 5%;
  align-items: center;
  display: flex;

  @include min-size(sm) {
    padding: 0 5%;
  }


  .row {
    align-items: center;
  }

  .logo {
    flex: 1;
    a {
      display: flex;
      align-items: center;
    }
    .image,
    .wordmark {
      display: table-cell;
      vertical-align: middle;
    }
    img {
      height: auto;
      width: 100%;
      max-width: 40px;
      margin-right: 10px;
    }
    p {
      font-size: 0.5em;
      font-weight: 300;
    }

    .wordmark {
      color: $color-grey;
      font-size: 1.4em;
      font-weight: 600;

      span {
        color: $color-brand;
      }
    }
  }

  .close {
    font-size: 2em;
    position: absolute;
    right: 1em;
    top: 1em;
    @include min-size(sm) {
      display: none;
    }
  }

  .mobile-navigation {
    display: flex;
    width: 2.5em;
    height: 2em;
    flex-direction: column;

    @include min-size(sm) {
      display: none;
    }



    span {
      background: $color-brand;
      content: '';
      flex: 1;
      display: block;
      margin: .2em;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    li {

      a {
        color: $color-grey;
        font-size: 1em;
        // font-weight: 500;
        display: block;
        &.active,
        &:hover {
          color: $color-brand;
        }
      }

      &.heart {
        position: relative;

        > .button {
          padding: 0;
          background: none;
          color: inherit;
          font-size: 1.5em;
          position: relative;
          display: inherit;
          margin-right: 20px;
          outline: 0;

          .number {
            background: $color-brand;
            border: 2px solid $color-brand;
            border-radius: 10px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            position: absolute;
            top: -5px;
            right: -10px;
            font-size: 0.5em;
          }
        }

        .shortlist {
          position: absolute;
          width: 300px;
          background: $color-light-grey;
          border: 1px solid $color-light-grey;
          padding: 20px;
          top: 4em;
          right: 0;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;

          &:before {
            content: '';
            bottom: 100%;
            right: 14px;
            border: solid transparent;
            position: absolute;
            pointer-events: none;
            border-bottom-color: rgba(0, 0, 0, 0.1);
            border-width: 16px;
          }

          &:after {
            content: '';
            bottom: 100%;
            right: 15px;
            border: solid transparent;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $color-light-grey;
            border-width: 15px;
            margin-left: -15px;
          }

          h2 {
            margin: 0;
            font-size: 1.2em;
            font-weight: 300;
            color: $color-brand;
            padding-bottom: 10px;
            border-bottom: 1px solid $color-light-grey;
          }

          h3 {
            font-weight: 400;
            margin-bottom: 0;
          }

          .list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 0.2em;
          }

          .item {
            p {
              margin: 0;
            }
          }

          .thumbnails {
            display: grid;
            grid-gap: 0.2em;
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }

      &.login {
        border: 1px solid $color-brand;
        padding: 10px 20px;
        transition: all 0.5s;
        a {
          color: $color-brand;
        }
        &:hover {
          background: $color-brand;
          color: white;
          a {
            color: white;
          }
        }
      }
    }
  }

  .links {
    display: none;
    @include max-size(sm) {
      &.show {
        display: flex;
        background: white;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        font-size: 1.4em;
      }
    }



    ul {
      justify-content: center;
      flex-direction: column;

      li {
        padding: 1em;
      }
    }


    @include min-size(sm) {
      display: flex;
      ul {
        justify-content: center;
        flex-direction: row;
      }
    }
  }
}
