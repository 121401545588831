.container {
    margin: auto;
    padding: 0 5%;
    @include min-size(sm) {
      padding: 0 5%;
    }
}

.main-content {
    padding-top: 70px;
    @include min-size(sm) {
        padding-top: 100px;
    }
    @include min-size(ms) {
        padding-top: 120px;
    }
}

.row {
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    flex-wrap: wrap;

    .row {
        margin: -5px;
    }
}

.flex-end {
    @include flex-end();
}

*[class*='col-'] {
    width: 100%;
    @include padding();

    @include min-size(sm) {
        // display: grid;
    }

}

.row.no-grid {
    *[class*='col-'] {
        display: initial;
    }
}

@each $size in $sizes {

    @include strict-size($size) {
        .#{$size}-hidden {
            display: none!important;
        }
    }

    @include min-size($size) {
        @for $i from 1 through 8 {
            .col-#{$size}-#{$i} {
                flex: $i;
            }
        }
    }
}
