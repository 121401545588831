.footer {
  margin-top: 2em;
  // margin-bottom: 3em;
  background: white;
  width: 100%;
  // border-top: 1px solid $color-light-grey;
  @include min-size(ms) {
    // padding: 20px 5px;
  }

  .row {
    display: flex;
  }

  a {
    color: $color-grey;
  }

  .copyright {
    margin-top: 3em;
    padding: 20px;
    border-top: 1px solid $color-light-grey;
    display: flex;
    background: $color-light-grey;

    &__wordmark {
      flex: 1;
    }

    &__links {
      flex: 1;
      text-align: right;
      color: $color-mid-grey;
    }

    img {
      max-width: 40px;
      margin-right: 10px;
    }

    p {
      margin: 0;
    }

    a {
      color: inherit;
      font-weight: 600;
    }
  }

  .social {
    .icon {
      font-size: 2em;
      margin-right: 5px;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 1.2em;
    span {
      color: $color-brand;
    }

    &.brand {
      font-weight: 600;
    }
  }

  .app {
    background: $color-brand;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
    font-size: 1em;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 5px 0;
    }
  }
}
