.gallery {
    .thumbnail {
        animation: fadein 2s;
        width: 100%;
    }

    button {
        background: white;
        border: 1px solid $color-mid-grey;
        color: $color-grey;
        margin: 10px auto;
        letter-spacing: 0.2em;
        display: block;
        @include padding(10px 40px);
        text-transform: uppercase;
        @include font();
        font-weight: bold;
        cursor: pointer;
        outline: none;
        &:hover {
            background: $color-mid-grey;
        }
    }
}
