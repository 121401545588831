.testimonials-component {
    margin: 4em 5%;
    font-size: 1.2em;
    // border: 1px solid $color-mid-grey;
    padding: 2em;
    background: $color-light-grey;

    .list {
        // background: $color-super-light;
        display: grid;
        grid-gap: 4em;
        @include min-size(sm) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .testimonial {
        display: flex;
    }

    .quote {
        margin-top: .5em;
        margin-right: .5em;
        font-size: 1.2em;
        color: $color-brand;
    }

    .copy {
        flex: 1;
    }

    .author {
        font-weight: 600;
        color: $color-brand;
    }
}
