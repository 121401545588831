@mixin point($color: $color-main) {
    &::after {
        content: '.';
        color: $color;
    }
}

@mixin padding($padding: 5px) {
    padding: $padding;
}

@mixin flex-end(){
    display: flex;
    justify-content: flex-end;
}

@mixin font(){
    font-family: "Open Sans",sans-serif;
}

@mixin burger-line() {
    font-size: 0;
    padding: 0;
    height: 0;
    margin: 0;
    border: 0;
    position: relative;
    background: none;
    border: 1.5px solid $color-main;
}

@mixin min-size($media) {
  @if $media == xs {
    @media only screen and (min-width: ($break-xsmall + 1)) { @content; }
  }

  @if $media == sm {
    @media only screen and (min-width: ($break-small + 1)) { @content; }
  }
  @else if $media == ms {
    @media only screen and (min-width: ($break-medium + 1)) { @content; }
  }
  @else if $media == ls {
    @media only screen and (min-width: ($break-large + 1)) { @content; }
  }
}

@mixin max-size($media) {
  @if $media == sm {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == ms {
    @media only screen and (max-width: $break-medium) { @content; }
  }
  @else if $media == ls {
    @media only screen and (max-width: $break-large) { @content; }
  }
}

@mixin strict-size($media) {
  @if $media == xs {
    @media only screen and (max-width: ($break-xsmall - 1)) { @content; }
  }
  @if $media == sm {
    @media only screen and (min-width: $break-xsmall + 1) and (max-width: ($break-small)) { @content; }
  }
  @else if $media == ms {
    @media only screen and (min-width: $break-small + 1) and (max-width: ($break-medium)) { @content; }
  }
  @else if $media == ls {
    @media only screen and (min-width: $break-medium + 1) and (max-width: ($break-large)) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: $break-large + 1) { @content; }
  }
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}
