.member-signup {
  justify-content: center;
  background: $color-light-grey;
  font-size: 1em;
  margin: 0;

  @include min-size(sm) {
    font-size: 1.2em;
    // padding: 2em 25%
  }

  h1 {
    font-weight: 300;
    // color: $color-brand;
    margin: .5em 0;
      font-size: 1.2em;
      @include min-size(sm) {
        font-size: 2em;
      }
  }

  form {
    grid-gap: .5em;
    border: none;
  }

  h2 {
    font-weight: 400;
    margin: 0;
  }

  .form {
    width: 100%;
    position: relative;
    color: $color-brand;
    justify-self: center;
    grid-gap: 1em;
    color: $color-mid-grey;
    max-width: 640px;

    input {
    font-size: 1em;
    -webkit-appearance: none;
    border-radius: 0px;
    color: $color-grey;
    border: solid 1px #DEDEDE;
    border-left: solid 3px $color-brand;
    outline: none;
    padding: 0.75rem 1rem;
    min-height: 3rem;
    margin: 0px;
    margin-right: 0.7em;
    margin-bottom: 1rem;
    width: 100%;

    @include min-size(sm) {
      max-width: 22em;
    }
    }


  }

  .email {
    padding: 0.5em;
    background: white;
    display: flex;
    align-items: center;

      .icon {
        font-size: 1.5em;
        margin: 5px;
      }
    }
    button {
      background: $color-brand;
      color: white;
      border: none;
      padding: 1.2em 2em;
      font-size: .8em;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }
