$fa-font-path: "~font-awesome/fonts";
@import "../../node_modules/font-awesome/scss/font-awesome";

hr {
    margin-top: 2vw;
    margin-bottom: .25vw;
    border: 0;
    background: white;
    height: 3px;
    &.skinny {
        height: 1px;
    }
}

.fa-stack {
    margin-top: 5px;
    font-size: 1em;
    .fa:first-child {
        color: $color-grey;
    }
}

.small {
    font-size: 0.8em;
}
