ul {
    margin: .5em 0 0;
    &.plus {
        list-style: none;
        padding: 0;
        font-size: 1em;
        letter-spacing: -0.05em;
        line-height: 1em;
        font-weight: bolder;
        &.black {
            color: $color-black;
        }

        li {
            font-size: 1em;
            width: 100%;
            line-height: 1.2em;
            margin-left: .6em;
            @include min-size(sm) {
                font-size: 1vw;
            }

            &:before {
                content: '+';
                position: absolute;
                margin-left: -.6em;
            }
        }

        &.white li {
            margin-left: .8em;
            &:before {
                margin-left: -.8em;
            }
        }
    }

    &.square {
        list-style: none;
        margin-top: 20px;
        padding: 0;
        font-size: 1em;
        letter-spacing: -0.03em;
        line-height: 1em;
        font-weight: bolder;
        &.black {
            color: $color-black;
        }

        li {
            font-size: 1em;
            width: 100%;
            line-height: 1em;
            margin: 5px 0;
            @include min-size(sm) {
                font-size: 1vw;
            }
        }

        li:before {
            content: '·';
            margin-right: 1em;
        }
    }
}