.villa-item {
  position: relative;
  .main-image {
    position: relative;
  }

  .images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.2em;
    margin-top: 0.2em;

    .image {
      position: relative;
    }

    .image:nth-of-type(1) {
      grid-column: span 3;
    }
  }

  .content {
    display: flex;

    .info {
      flex: 3;
    }

    .rates {
      font-size: 0.8em;
      text-transform: uppercase;
      color: $color-brand;
      font-weight: 600;
      p {
        text-transform: initial;
        font-weight: 600;
        color: $color-grey;
        font-size: 1.2em;
      }
    }

    .sleeps {
      color: $color-mid-grey;
      font-size: 0.8em;
    }

  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      padding: 10px 20px;
      font-weight: 600;
      font-size: 1em;
    }


  }

  a {
    color: $color-grey;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    font-weight: 400;
    text-transform: uppercase;
  }

  p {
    margin: 0;

    &.location {
      text-transform: uppercase;
      margin-top: 5px;
      font-size: .8em;
      color: $color-mid-grey;
    }

    &.rates {
      margin: 0;
      font-weight: 600;
      color: $color-mid-grey;
      span {
        color: $color-brand;
      }
    }

    &.offer {
      color: $color-brand;
    }

  }

  &.placeholder {
    text-align: center;
  }

  .favourite {
      position: absolute;
      top: 0;
      right: 10px;
      background: $color-brand;
      padding: 10px;
      line-height: 1em;
      color: white;
      font-size: 1.5em;
      cursor: pointer;
  }


}
