@import './variables';
@import './colours';
@import './mixins';
@import './animation';
@import './placeholder';
@import './box';
@import './gallery';
@import './list';
@import './misc';
@import './grid';
@import '../modules/modules';

@import './fonts';

body {
    margin: 0;
    padding: 0;
    background: $color-bg;
    color: $color-main;
    @include font();
    font-size: 14px;
    font-weight: 400;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: $color-brand;
}

img {
    max-width: 100%;
    vertical-align: top
}

h1, h2, h3, h4 {
  font-weight: 200;
}

textarea {
  @include font();
}

.button {
  background: $color-brand;
  color: white;
  display: inline;
  border: none;
  padding: 1em 2em;
  font-size: 1em;
  font-weight: 300;
  cursor: pointer;
  &.full-width {
    width: 100%;
  }

  &.blue {
    background: #33CCCC;
    color: white;
  }

  &.link {
    background: none;
    color: $color-brand;
    padding: 0;
  }

  &:disabled {
    cursor: initial;
    background: $color-light-brand;
  }
}

.strip {
  padding: 30px 0;

  &.border-top {
    border-top: 1px solid $color-super-light;
  }

  &.padding-top-none {
    padding-top: 0;
  }

  &.grey {
    background-color: $color-super-light;
  }
}


.text-center {
  text-align: center;
}
